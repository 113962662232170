@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

$height: 35px;

.file_upload_wrap {
    margin-top: 10px;

    .camera_icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        img {
            display: block;
            max-width: 30px;
            width: 30px;
            height: auto;
            border-radius: 3px;
        }
    }
    .d_none {
        display: none;
    }
    .file_upload {
        position: relative;
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
        @include mixin.itemsCenter;
        height: $height;
        border: 2px solid rgba($color: variables.$greyD, $alpha: 0.2);
        @include mixin.borderRadius;
        overflow: hidden;
        background-color: variables.$white;
    }

    

    .file_clear {
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: variables.$error;
        min-width: 30px;
        border: none;

        svg {
            color: variables.$white;
        }
    }
    .disabled_btn {
        background-color: variables.$greyD;
        display: none;
    }
    .center {
        justify-content: center;
    }
    .btn_upload {
        position: relative;
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        cursor: pointer;
        overflow: hidden;
        @include mixin.itemsCenter;
        background-color: transparent;

        .text_browse {
            font-size: 12px;
            margin-left: 10px;
            @include mixin.itemsCenter;
            svg {
                color: variables.$primary;
                @include mixin.itemsCenter;
            }
        }
    }
    .text_file_name {
        max-width: 170px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: 0;
        text-align: left;
        font-size: 14px;
    }
    .form_group {
        label {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 5px;
        }
    }
}
