@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.card {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  max-width: 500px;
  border-radius: 6px;
  min-width: 100%;
  padding: 10px;
  @include mixin.boxShadow;
  background-color: variables.$white;
}

.custom_shadow {
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
}

.border {
  @include mixin.border;
}
