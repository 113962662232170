@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.grid_product_content {
    margin-right: 10px;
    min-width: 135px;
    max-width: 135px;
    &:last-child {
        margin-right: 0;
    }
}

.grid_product_horizontal {
    min-width: 215px;
    max-width: 215px;
}
.view_more {
    background-color: rgba($color: variables.$primary, $alpha: 0.1);
    border-radius: 10px;
    @include mixin.itemsCenter;
    justify-content: center;
    overflow: hidden;

    h3 {
        margin: 0;
        font-size: 14px;
        @include mixin.itemsCenter;
        color: variables.$primary;
        padding: 10px;
        height: 100%;
        width: 100%;
        justify-content: center;

        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }
}
