@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.cartBtn {
    @include mixin.flexColCenter;
    margin-left: 10px;
    color: variables.$dark;

    .cart_icon {
        position: relative;
        @include mixin.flexColCenter;
        .count {
            background-color: variables.$error;
            color: variables.$white;
            @include mixin.absolute(-5px, -5px);
            border-radius: 100%;
            width: 15px;
            height: 15px;
            @include mixin.flexCenter;
            font-size: 8px;
            font-weight: 500;
        }
    }
    span {
        font-size: 12px;
    }
}

.cart_btm_icon {
    span,
    svg {
        color: variables.$greyD;
    }
}
