@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.fileupload {
    background-color: variables.$white;
    label {
        border: 2px dashed variables.$primary;
        width: 100%;
        @include mixin.itemsCenter;
        @include mixin.borderRadius;

        input {
            display: none;
        }

        svg {
            margin: 10px;
            width: 42px;
            height: 42px;
            color: variables.$primary;
        }

        p {
            margin: 0;
            font-size: 14px;
            font-weight: 600;
        }

        span {
            color: variables.$greyD;
            font-size: 12px;
            font-weight: 400;
        }
    }
}
.prescription_btn {
    .icon {
        @include mixin.itemsCenter;
        svg {
            width: 40px;
            height: 40px;
            background-color: variables.$primary100;
            border-radius: 100%;
        }
    }
}

.fixed_btn {
    position: fixed;
    z-index: 999;
    top: 45%;
    left: auto;
    right: variables.$cp;
    @include mixin.itemsCenter;

    svg {
        width: 50px !important;
        height: 50px !important;
    }

    .prescription_design {
        @include mixin.boxShadow2;
        @include mixin.borderRadius;
        font-size: 18px;
        font-weight: 400;
        padding: 10px;
        @include mixin.itemsCenter;
        background-color:variables.$grey200;

        b {
            font-size: 24px;
            font-weight: 300;
            @include mixin.itemsCenter;
            line-height: 0;
            margin-right: 10px;
        }
    }
}

.fix_btm {
    top: auto;
    bottom: 15%;
}

.my_prescription_link {
    font-weight: 600;
    color: variables.$dark;
    font-size: 12px;
    border: 2px solid rgba($color: variables.$greyD, $alpha: 0.2);
    @include mixin.borderRadius;
    padding: 4px;
    @include mixin.itemsCenter;
    justify-content: center;

    svg {
        color: variables.$primary;
        margin-left: 10px;
    }
}
