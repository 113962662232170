@use "@/services/scss/_variables.scss";

.input {
  &::placeholder {
    color: variables.$grey300;
  }

  height: 40px;
  width: 100%;
  border: 1px solid rgba(variables.$greyD, 0.25);
  border-radius: 8px;

  padding: 0 10px;
  transition:
    border 0.1s ease-in,
    outline 0.1s ease-out;

  &:focus {
    border: 1px solid variables.$primary;
    outline: none;
    background-color: transparent;
  }
}

.error_req {
  color: variables.$error;
  margin-bottom: 0;
  font-weight: 500;
  // text-transform: capitalize;
  font-size: 12px;
}

.error_input {
  border: 1px solid variables.$error;

  &:focus {
    border: 1px solid variables.$error;
  }
}
