@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.layout_min_height {
    min-height: 100vh;
    background-color: variables.$grey100;
}

.category_wrap {
    position: relative;
    background-color: variables.$grey100;
    // background-color: red;
    // min-height: 100vh;
    .cat_product {
        margin-left: variables.$sideBarWidth;
        overflow: hidden;

        // .top_header_side_scroll{
        //     display: flex;
        //     overflow-y: auto;
        //     overflow-x: hidden;
        //     overflow: auto;
        // }

        .banner_wrapper {
            // margin: 3px 0;
            padding: 0 3px;
            position: relative;
            // background-color: red;
            background-color: variables.$grey100;
            .top_header_fixed {
                position: fixed;
                top: 50px;
                // right: 12px;
                // overflow: hidden;
                left: variables.$sideBarWidth;
                padding: 2px 3px;
                width: calc(100% - (variables.$sideBarWidth * (100 / 100)));
                max-width: variables.$maxWdt;
                // right: -$sideBarWidth;
                z-index: 999;
                background-color: variables.$grey100;
                // background-color: green;
            }

            @media only screen and (min-width: 768px) {
                .top_header_fixed {
                    left: calc(50% - (variables.$maxWdt - 2px - variables.$sideBarWidth * 2) / 2);
                    max-width: variables.$maxWdt - variables.$sideBarWidth - 2px;
                }
            }

            .category_load_banner {
                padding-top: 38px;
                margin-bottom: 1px;
            }
            .category_load_banner_m0 {
                padding-top: 36px;
            }
        }

        .item_wrap {
            font-size: 10px;
            font-weight: 500;
            padding: 10px;
            border: 1px solid variables.$grey200;
            background-color: variables.$white;
            @include mixin.borderRadius;
            margin-right: 2px !important;
            @include mixin.lastChildMargin;
            @include mixin.itemsCenter;
            justify-content: center;
            color: variables.$dark;
            // min-width: 80px;
            white-space: nowrap;

            svg {
                width: 10px;
                height: 10px;
                margin-left: 5px;
            }
            .icon_wrp {
                @include mixin.itemsCenter;
                svg {
                    width: 12px;
                    height: 12px;
                    @include mixin.itemsCenter;
                }
            }
        }

        .active_items {
            background-color: rgba($color: variables.$primary, $alpha: 0.3);
            // color: variables.$white;
        }
        .reset {
            background-color: rgba($color: variables.$error, $alpha: 0.5);
        }
    }
}

.category_initial_products {
    padding-left: variables.$sideBarWidth;
    padding-top: 87px;
    background-color: variables.$grey100;
    // background-color: red;

    .banner_img {
        overflow: hidden;
        padding: 0 4px;
        margin-bottom: 2px;
        margin-top: 2px;
    }
    .sub_taxonomy_list {
        padding: 0 3px;
    }
}

.category_initial_products_without_sidebar {
    padding-left: 0;
    padding-top: 31px;
}

.sort {
    padding: 0 15px;
    label {
        @include mixin.itemsCenter;
        font-size: 12px;
        font-weight: 500;
        padding: 10px 0;
        svg {
            width: 24px;
            height: 24px;
            color: variables.$grey300;
            margin-right: 8px;
        }
    }
}
.filters {
    @include mixin.flex;
    height: 310px;

    .titles {
        padding: 0 5px 0 10px;
        font-size: 12px;
        @include mixin.flexColumn;
        border-right: 1px solid variables.$grey300;
        width: 85px;

        span {
            padding: 5px 5px;
            margin: 5px 0;
            display: block;
            // word-break: break-all;
        }

        .label_filter {
            text-transform: capitalize;
        }

        .active {
            color: variables.$primary;
            font-weight: 600;
            position: relative;
            text-transform: capitalize;
            // border-right: 2px solid variables.$primary;

            &::after {
                content: "";
                position: absolute;
                width: 2px;
                height: 100%;
                display: block;
                left: auto;
                right: -6px;
                top: 0;
                border-radius: 2px 0 0 2px;
                background-color: variables.$primary;
            }
        }
    }

    .select {
        // margin-left: 10px;
        padding: 10px;
        // width: 100%;

        .search_input {
            border: 1px solid variables.$grey300;
            overflow: hidden;
            @include mixin.itemsCenter;
            @include mixin.borderRadius;
            // width: 100%;

            div {
                @include mixin.itemsCenter;
                padding: 0 10px;
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
            input {
                padding: 10px 0;
                border: none;
                width: 100%;

                &:focus {
                    outline: none;
                }
            }
        }

        .checkbox_list {
            max-height: 300px;
            overflow-x: hidden;
            overflow-y: scroll;

            & > label {
                @include mixin.itemsCenter;
                padding: 5px 0;
                margin: 5px 0;
                font-size: 12px;
                font-weight: 500;
                word-wrap: break-word;
                text-transform: capitalize;

                svg {
                    width: 25px;
                    height: 25px;
                    color: variables.$grey300;
                    @include mixin.itemsCenter;
                    margin-right: 10px;
                }

                .active {
                    svg {
                        color: variables.$primary;
                    }
                }
            }
        }
        .brand_checkbox_list {
            max-height: 250px;
        }

        .no_match_search {
            font-size: 12px;
            nav-down: 500;
            display: block;
            text-align: center;
            padding-top: 10px;
            color: variables.$error;
        }
    }
}

.sidebar {
    width: variables.$sideBarWidth;
    height: 100vh;
    position: fixed;
    padding-top: 5px;
    z-index: 999;
    // border-right: 1px solid variables.$grey200;
    overflow-y: scroll;
    @include mixin.scrollBarTransparent;
    top: 50px;
    background-color: variables.$white;
    transition: top 0.3s ease-in-out;
}

.sidebar_list_wrp {
    a {
        padding: 5px;
        color: variables.$greyD;
        font-size: 10px;
        font-weight: 500;
        display: block;

        .img_wrp {
            width: 55px;
            height: 55px;
            background-color: rgba($color:variables.$grey200, $alpha: 0.7);
            border-radius: 100%;
            @include mixin.itemsCenter;
            overflow: hidden;
            padding: 5px;
            @include mixin.transition3;

            img {
                object-fit: contain;
                aspect-ratio: 1;
                margin-top: 20px;
                @include mixin.transition3;
            }
        }
        h3 {
            // word-break: break-all;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
            margin-top: 5px;
        }
    }
    .active {
        color: variables.$primary;
        // border-right: 2px solid variables.$primary;
        position: relative;

        .img_wrp {
            background-color: rgba($color: variables.$primary, $alpha: 0.1);
            @include mixin.transition2;
            // padding: 0;
            overflow: hidden;

            // img {
            //     // transform: scale(1.1);
            // }
            img {
                @include mixin.transition2;
                margin-top: 0px;
            }
        }

        &::after {
            content: "";
            z-index: 999999999;
            position: absolute;
            width: 4px;
            height: 100%;
            display: block;
            left: auto;
            right: 0;
            top: 0;
            border-radius: 10px 0 0 10px;
            // @include mixin.transition5;
            transition: top 0.5s ease;
            background-color: variables.$primary;
            // animation: slideDown 0.5s backwards;
            // animation: slideDown 0.5s backwards;
        }
        &.activatedUp::after {
            content: "";
            animation: slideDown 0.5s backwards;
        }
        &.activatedDown::after {
            content: "";
            animation: slideDown 0.5s backwards;
        }
        @keyframes slideUp {
            from {
                top: 100%;
            }
            to {
                top: 0;
            }
        }
        @keyframes slideDown {
            from {
                top: -100%;
            }
            to {
                top: 0;
            }
        }
    }
}

.sort_btn_wrap {
    @include mixin.itemsCenter;

    .item_wrap {
        font-size: 10px;
        padding: 10px;
        border: 1px solid variables.$grey200;
        background-color: variables.$white;
        @include mixin.borderRadius;
        margin-right: 10px !important;
        @include mixin.itemsCenter;
        justify-content: center;
        color: variables.$dark;
        // min-width: 80px;
        white-space: nowrap;

        svg {
            width: 10px;
            height: 10px;
            margin: 0 5px;
        }
        .icon_wrp {
            @include mixin.itemsCenter;
            svg {
                width: 12px;
                height: 12px;
                @include mixin.itemsCenter;
            }
        }
    }
}

.btn_fixed_btm {
    position: fixed;
    top: auto;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 80%;
    max-width: 250px;
    @include mixin.flexBetween;
    align-items: center;
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0 2px 10px variables.$grey300;
    animation: fadeOut 0.5s ease-in-out;
    @keyframes fadeOut {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    button {
        margin: 0;
        width: 100%;
        background-color: variables.$white;
        border: none;
        padding: 8px;
        @include mixin.itemsCenter;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;

        &:not(:last-child) {
            border-right: 1px solid variables.$grey200;
        }

        svg {
            margin-right: 10px;
        }
    }
    .active {
        color: variables.$primary;
    }
}

.item_wrapper_modal {
    @include mixin.itemsCenter;
    flex-direction: row;
    padding-top: 1px;

    .item_wrap_modal {
        font-size: 12px;
        background-color: variables.$white;
        padding: 8px;
        @include mixin.itemsCenter;
        @include mixin.borderRadius;
        border: 1px solid variables.$grey200;
        min-width: 80px;
        margin-right: 2px;
        justify-content: center;
        white-space: nowrap;

        svg {
            width: 15px;
            height: 15px;
            @include mixin.itemsCenter;
        }
    }

    .clear_all {
        background-color: rgba($color: variables.$error, $alpha: 0.3);
    }

    .active_items_modal {
        background-color: rgba($color: variables.$primary, $alpha: 0.4);
    }
}

.beauty_category {
    .item_wrap_modal {
        width: 80px;
        height: 80px;
        white-space: normal;
        word-break: break-all;
        text-align: center;
        background: url("../../services/utils/icons/beauty-cat-bg.png") no-repeat center;
        font-size: 14px;
        font-weight: 600;
        color: variables.$white;
        overflow: hidden;
    }
    .clear_all {
        background: variables.$error;
    }
    .active_items_modal {
        background: rgba($color: variables.$primary, $alpha: 1);
    }
}

.apply_filters_btn {
    @include mixin.itemsCenter;
    justify-content: center;
    border-top: 1px solid variables.$grey300;
    padding: 10px 20px;

    button {
        background-color: variables.$white;
        border: 1px solid variables.$primary;
        width: 100%;
        margin-right: 10px;
        @include mixin.borderRadius;
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
        color: variables.$primary;

        &:last-child {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .clear_filter {
        border: 1px solid variables.$error;
        color: variables.$error;
    }

    .active {
        color: variables.$white;
        background-color: variables.$primary;
    }

    .disabled {
        border: 1px solid rgba($color: variables.$grey300, $alpha: 0.5);
        color: rgba($color: variables.$dark, $alpha: 0.2);
        &.active {
            background-color: rgba($color: variables.$grey300, $alpha: 0.5);
        }
    }
}

// .sort_filter_modal_content {
//     min-height:  !important;
//     max-height: 55vh !important;

//     // .sort_filter_modal_child {
//     // }
// }

.category_modal_sticky {
    position: sticky;
    top: 100px;
}

.next_category {
    background: rgb(8, 80, 45);
    background: linear-gradient(
        0deg,
        rgba(8, 80, 45, 0.5662640056022409) 0%,
        rgba(14, 170, 94, 1) 35%,
        rgba(245, 246, 251, 0.8575805322128851) 100%
    );
    @include mixin.itemsCenter;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 20px;

    .scroll_item_wrap {
        position: relative;
        .icons {
            @include mixin.flexColumn;
            position: absolute;
            top: -50px;
            left: 35%;
            transform: translate(-50%, -50%);

            svg {
                position: absolute;
                rotate: -90deg;

                &:first-child {
                    top: 10px;
                }
            }
        }
        h3 {
            font-size: 12px;
            font-weight: 600;
            text-align: center;
        }
        & > div {
            margin: 0 auto;
        }
    }
    .img_wrap {
        width: 70px;
        height: 70px;
        background-color: variables.$white;
        border-radius: 100%;
        img {
            aspect-ratio: 1;
        }
    }
}

.not_found {
    padding: 10px;
    @include mixin.itemsCenter;
    justify-content: center;
    flex-direction: column;

    svg {
        width: 200px;
        height: 200px;
    }
    img {
        max-width: 244px;
    }

    .content {
        @include mixin.itemsCenter;
        justify-content: center;
        flex-direction: column;
        p {
            background-color: variables.$secondary200;
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            @include mixin.borderRadius;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 1.5px;
                //
                vertical-align: middle;
                background-color: variables.$dark;
            }
            &::before {
                margin-right: 10px;
            }
            &::after {
                margin-left: 10px;
            }
        }
        span {
            font-weight: 400;
            font-size: 14px;
            color: variables.$greyD;
        }
    }
}
