@use '@/services/scss/variables.scss';
@use '@/services/scss/mixin.scss';


.riderInfo {
    display: flex;
    flex-direction: column;
    margin: 0;

    .delivere {
        font-size: 12px;
        margin-bottom: 12px;
        font-weight: 600;
    }

    .rider {
        display: flex;
        justify-content: space-between;
        .profile {
            display: flex;

            .image {
                margin-right: 8px;
                width: 44px;
                height: 44px;
                border-radius: 50%;
            }

            .name {
                display: flex;
                flex-direction: column;

                .nameText {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 19.2px;
                    margin: 0;
                }

                .statusText {
                    color: var(--ltGreyscale600, #718096);
                    font-size: 12px;
                    line-height: 19.2px;
                }
            }
        }
        .riderButton {
            font-size: 10px;
            color: variables.$white;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: variables.$ltPrimary;
            padding: 0 8px;
            height: 24px;
            border-radius: 4px;
            width: fit-content;
            cursor: pointer;
            border: none;
            white-space: nowrap;
            margin-left: 8px;
        }
    }
}
.reviewBlock {
    margin-top: 16px;
}