@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.out_off_stock {
    font-size: 10px;
    color: variables.$error;
    padding-left: 0;
    background-color: variables.$dark;
    color: variables.$white;
    border-radius: 4px;
    padding: 2px 5px;
    display: inline-block;
    text-transform: capitalize;
}

.delivery_type {
    p {
        background-color: variables.$primary;
        @include mixin.itemsCenter;
        @include mixin.borderRadius;
        padding: 3px 8px;
        color: variables.$white !important;
        margin: 0;
        font-size: 11px !important;
        svg {
            color: variables.$white;
            width: 16px;
            height: 16px;
            @include mixin.itemsCenter;
            margin-right: 5px;
        }
    }
    .express {
        background-color: #323b49;
        color: variables.$warning;
        svg {
            color: variables.$warning;
        }
    }
}

.circle_icon {
    p {
        max-width: 30px;
        @include mixin.itemsCenter;
        justify-content: center;
        padding: 0;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        padding: 4px;

        svg {
            margin-right: 0;
        }
    }
}

.text {
    @include mixin.itemsCenter;
    font-size: 11px;
    span {
        display: block;
        margin-left: 5px;
    }
}
.product {
    p {
        padding-left: 15px;
        border-radius: 0px 30px 30px 0px;
    }
}
.bg_gray {
    background-color:variables.$grey200;
    display: inline-flex;
    padding: 3px 5px;
    @include mixin.borderRadius;

    span {
        color: variables.$dark !important;
        font-size: 10px;
    }
    p {
        height: 18px;
        width: 18px;
    }
}

.bg_success {
    background-color: rgba($color: variables.$primary, $alpha: 0.3);
    span {
        color: variables.$dark !important;
    }
}

.custom_change {
    // @include mixin.itemsCenter;
    border-radius: 3px;
    svg {
        width: 10px !important;
        height: 10px !important;
        @include mixin.itemsCenter;
    }
    span {
        font-size: 8px;
        @include mixin.itemsCenter;
        line-height: 0;
    }
}

.my_order_design {
    color: variables.$primary;
    background-color: rgba($color: variables.$primary, $alpha: 0.1);
    padding: 3px 5px;

    svg {
        color: variables.$primary;
        width: 14px;
        height: 14px;
    }
    span {
        color: variables.$primary !important;
        font-size: 10px !important;
        font-weight: 500;
    }
}

.delivery_type_modal_design {
    h3 {
        font-size: 14px;
        margin: 0;
        margin-bottom: 10px;
        font-weight: 500;
    }

    p {
        font-size: 12px;
        font-weight: 400;
    }
}
