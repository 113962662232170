@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.order_list_wrp {
    .order_list {
        padding: 0;

        .header {
            padding: 10px;
            @include mixin.itemsCenter;
            justify-content: space-between;
            border-bottom: 1px solid variables.$grey200;
        }
        .content {
            padding: 10px;

            & > div {
                margin-top: 10px;
                &:first-child {
                    margin-top: 0;
                }
                @include mixin.flexBetween;
            }
        }
    }
}

.order_details_wrp {
    @include mixin.border;
    @include mixin.borderRadius;
    @include mixin.boxShadow2;

    .order_details {
        @include mixin.itemsCenter;
        padding: 10px;

        & > div {
            width: 100%;
        }

        .lines {
            max-width: 30px;

            @include mixin.flexColumn;
            align-items: center;
            // justify-content: center;
            // align-items: center;
        }
    }
    .flex_center {
        @include mixin.itemsCenter;
        flex-direction: column;
        border-top: 1px solid variables.$grey200;
        padding-top: 10px;
    }
}
