@use '@/services/scss/_variables';

.my-order-review {
    min-height: 100vh;
    background: variables.$white;
    font-size: 14px;
    .navigation-list {
        box-shadow: 0 2px 15px rgb(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        a {
            color:  variables.$ltGreyScale600;
            display: flex;
            align-items: center;
            padding: 10px;
            gap: 15px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
                display: flex;
                align-items: center;
                gap: 10px;
            }
            &:not(:last-child) {
                border-bottom: 1px solid variables.$ltGreyScale300;
            }
        }
    }
}
