@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.category {
    .cat_details {
        padding: 7px;
        border-radius: 10px;
        @include mixin.flexCenter;

        img {
            margin: 0 auto;
            object-fit: contain;
            aspect-ratio: 1;
        }
    }
    h3 {
        font-size: 12px;
        text-align: center;
        padding-top: 9.75px;
        font-weight: 600;
        margin: 0;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: normal;
    }

    .drag {
        min-width: 60px;
        max-width: 60px;
        margin-right: 10px;
        color: variables.$dark;
        &:last-child {
            margin-right: 0;
        }
        .cat_details {
            background-color: variables.$white;
            border: 1px solid #f1f2f4;
            border-radius: 6px;
            @include mixin.flexColCenter;
            padding: 5px;

            img {
                width: 36px !important;
                height: 36px !important;
            }
        }

        .offer {
            font-size: 8px;
            font-weight: 600;
            line-height: 1;
            display: block;
            @include mixin.ellipsis;
            text-align: center;
            margin-bottom: 5px;
        }
        h3 {
            @include mixin.ellipsis;
            padding-top: 5px;
            font-weight: 400;
            font-size: 10px;
            text-align: center;
        }
    }
}

.cat_wrap {
    position: fixed;
    z-index: 999;
    top: auto;
    bottom: 100px;
    left: auto;
    right: variables.$cp;

    @include mixin.itemsCenter;

    & > span svg {
        rotate: 90deg;
        background-color: variables.$dark;
        color: variables.$white;
        @include mixin.borderRadius;
        margin-right: 5px;
    }

    .cat_modal {
        background-color: #323b49;
        color: variables.$white;
        width: 70px;
        height: 70px;
        padding: 10px;
        @include mixin.flexColCenter;
        border-radius: 100%;
        margin-right: -50px;
        transition: all 0.6s ease-in-out;

        svg {
            width: 35px;
            height: 35px;
            margin-bottom: 3px;
        }

        span {
            font-size: 7px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    .expand_more {
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) {
    .cat_wrap {
        right: calc(50% - (variables.$maxWdt - variables.$cp + 12px) / 2);
    }
}

.active {
    color: variables.$primary !important;
}

.shop_by_category {
    // @include mixin.itemsCenter;

    a {
        padding: 5px;
        color: variables.$dark;
        font-size: 14px;
        font-weight: 600;
        display: block;
        @include mixin.itemsCenter;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .img_wrp {
            width: 100%;
            background-color: rgba($color:variables.$grey200, $alpha: 0.7);
            @include mixin.itemsCenter;
            overflow: hidden;
            // @include mixin.borderRadius;
            border-radius: 10px;

            img {
                object-fit: contain;
                aspect-ratio: 1;
            }
        }
        h3 {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            margin: 0;
            margin-top: 5px;

            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }
    }
}

.modal_design {
    background-color: transparent !important;
}
.child_design {
    background-color: variables.$white;
    margin: 0 12px;
    @include mixin.borderRadius;
    padding: 10px 0 !important;
    max-height: 70vh !important;
}