@use '@/services/scss/variables.scss';
@use '@/services/scss/mixin.scss';


.reviewInfo {
    display: flex;
    flex-direction: column;
    margin: 0;

    .reviewAction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        .name {
            font-size: 12px;
            color: #718096;
        }

        .user {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .actionIcon {
            cursor: pointer;
            font-size: 20px;
            color: #667085;
        }

        .menu {
            position: absolute;
            top: 30px; // Adjust as needed
            right: 0;
            background-color: white;
            border: 1px solid #EEEFF2;
            border-radius: 4px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            z-index: 10;
        }

        .menuItem {
            padding: 8px 12px;
            cursor: pointer;
            border: none;
            background: none;
            width: 100%;
            text-align: left;
            font-size: 14px;

            &:hover {
                background-color: #f1f1f1;
            }
        }
    }

    .reviewText {
        font-size: 12px;
        font-weight: 400;
        color: variables.$ltGreyScale700;
        margin-top: 6px;
        margin-bottom: 4px;
    }

    .reviewStatus {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
        color: white;
        padding: 0 8px;
        height: 15px;
        border-radius: 9999px;
        background-color: variables.$ltOrange;
        width: fit-content;
        margin: 0;
    }

    .images {
        display: flex;
        gap: 6px;
        margin: 8px 0px;

        .image {
            width: 48px;
            height: 48px;
            border-radius: 5px;
            object-fit: cover;
            border: 1px solid variables.$ltGreyScale300;
            cursor: pointer;
        }
    }

    .full_screen_modal {
        min-height: 100% !important;
        border-radius: 0 !important;

        &>h3 {
            border-bottom: 0 !important;
        }

        .modal_child_content {
            max-height: 100% !important;
            padding: 0 !important;
            min-height: 80vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}