@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";
.starRatingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .starRating {
        display: flex;
        flex-direction: row;
    }
    
    .star {
        transition: fill 0.2s;
    }
    
    .ratingInput {
        display: none;
    }
    .ratingScore {
        font-size: 12px;
        font-weight: 500;
        color: variables.$ltTitle;
    }
}

