@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.payment_wrapper {
    .congrats {
        & > span {
            @include mixin.flexCenter;
            width: 100%;
            svg {
                // width: 138px;
                // height: 100px;

                width: 56px;
                height: 56px;
                color: variables.$primary;
            }
        }
        h3 {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            margin-top: 10px;
        }
        p {
            font-size: 12px;
            font-weight: 400;
            // @include mixin.itemsCenter;
            color: variables.$greyD;
            text-align: center;

            span,
            strong {
                font-weight: 500;
                color: variables.$dark;
            }
        }
    }
    .status {
        @include mixin.itemsCenter;
        justify-content: center;
        position: relative;

        div {
            @include mixin.flexColumn;
            z-index: 99;
            &:first-child {
                margin-right: 20px;
            }
            &:last-child {
                margin-left: 20px;
            }

            justify-content: center;
            b {
                font-size: 12px;
                font-weight: 400;
                text-align: center;
            }

            span {
                @include mixin.flexCenter;
                margin-bottom: 10px;
                svg {
                    width: 20px;
                    height: 20px;
                    background-color: variables.$white;
                }
            }
        }

        .line {
            width: 1px;
            height: 100%;
            background-color:variables.$grey200;
            display: block;
            position: absolute;
            min-width: 100px;
            height: 2px;
            top: 10px;
            z-index: 9;
        }
        .line.active {
            background-color: #0ba259;
        }

        .active {
            b {
                color: variables.$primary;
            }
        }
    }

    .details {
        margin-top: 10px;
        .block {
            display: flex;
            margin-bottom: 10px;
            svg {
                margin-right: 10px;
                width: 24px;
                height: 24px;
            }
            font-size: 12px;
            p {
                margin: 0;
                margin-bottom: 5px;
            }
            span {
                color: variables.$greyD;
            }
        }
    }

    .payment_options {
        padding: 0;
        .content {
            h3 {
                padding: 10px;
                font-size: 16px;
                font-weight: 600;
                border-bottom: 1px solid variables.$grey200;
            }
        }

        .payment_type {
            .options {
                & > div {
                    padding: 10px;
                    button {
                        background-color: transparent;
                        border: none;
                        font-size: 14px;
                        font-weight: 500;
                        @include mixin.itemsCenter;
                        margin-bottom: 10px;
                        padding: 0;

                        span {
                            margin-right: 10px;
                            @include mixin.itemsCenter;
                            svg {
                                width: 16px;
                                height: 16px;
                            }
                        }
                        .checkbox_icon {
                            svg {
                                color: variables.$primary;
                            }
                        }
                    }
                    .disable {
                        color: variables.$grey500;

                        span {
                            svg {
                                color: variables.$grey500;
                            }
                        }
                    }

                    .info_list {
                        .info {
                            @include mixin.borderRadius;
                            // @include mixin.itemsCenter;
                            display: flex;
                            padding: 6px 10px;
                            font-size: 12px;
                            font-weight: 400;

                            svg {
                                margin-right: 10px;
                                max-width: 15px;
                                min-width: 15px;
                                max-height: 15px;
                                min-height: 15px;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                        .info_blue {
                            background-color: rgba($color: variables.$blue, $alpha: 0.1);
                            svg {
                                color: variables.$blue;
                            }
                        }
                        .info_error {
                            background-color: rgba($color: variables.$error, $alpha: 0.1);
                            svg {
                                color: variables.$error;
                            }
                        }
                        .info_success {
                            background-color: rgba($color: variables.$primary, $alpha: 0.1);
                            svg {
                                color: variables.$primary;
                            }
                        }
                        .info_orange {
                            background-color: rgba($color: variables.$orange, $alpha: 0.1);
                            svg {
                                color: variables.$orange;
                            }
                        }
                    }
                }
                .cod_or {
                    margin-bottom: 10px;
                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: variables.$grey500;
                        @include mixin.itemsCenter;

                        &::before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: variables.$grey500;
                        }
                        &::after {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 1px;
                            background-color: variables.$grey500;
                        }
                    }
                }
            }
        }
    }

    .d_none {
        display: none;
    }
}

.info_bottom {
    position: fixed;
    top: auto;
    padding: 0 variables.$cp;

    .info_blue {
        background-color: variables.$white;
        border: 1px solid variables.$blue;
        @include mixin.borderRadius;
        padding: 10px;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        display: flex;

        span {
            display: block;
            display: flex;
            align-items: center;
        }
        .warning {
            display: flex;
            align-items: flex-start;
            margin-top: 2px;

            svg {
                color: variables.$blue;
                margin-right: 10px;
                width: 14px;
                height: 14px;
            }
        }
        .close {
            display: flex;
            align-items: center;
            svg {
                margin-left: 10px;
                width: 20px;
                height: 20px;
            }
        }
    }
}

.footer_content {
    width: 100%;
    button {
        background-color: variables.$primary;
        border: none;
        color: variables.$white;
        @include mixin.borderRadius;
        padding: 10px;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        @include mixin.flexCenter;

        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
    a {
        display: block;
        font-size: 14px;
        font-weight: 500;
        border: 1px solid variables.$primary;
        margin-top: 10px;
        @include mixin.borderRadius;
        @include mixin.flexCenter;
        padding: 10px;
        color: variables.$primary !important;
    }
}
