@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.top_header {
    width: 100%;
    position: fixed;
    z-index: 9999;
    @include mixin.transition;
    background-color: variables.$bodyColor;
    padding-top: 2px;

    .content {
        background-color: variables.$bodyColor;
        padding: 7px 0 5px 0;
    }
}
