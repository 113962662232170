@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.nav_item {
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    @include mixin.itemsCenter;
    margin-right: 10px;
    border-radius: 25px;
    @include mixin.lastChildMargin;
    background-color: transparent;
    border: none;
    text-transform: capitalize;
    color: variables.$greyD;
    min-width: 45px;
    justify-content: center;
}

.active {
    background-color: rgba($color: variables.$primary, $alpha: 0.2);
    color: variables.$primary;
}

.order_items {
    @include mixin.borderRadius;
    @include mixin.boxShadowOrder;
    margin-bottom: 10px;
    border: 1px solid variables.$grey200;
    display: block;
    color: variables.$dark;

    .title {
        @include mixin.itemsCenter;
        justify-content: space-between;
        font-size: 14px;
        margin: 0;
        border-bottom: 1px solid variables.$grey200;
        padding: 10px;

        span {
            @include mixin.itemsCenter;
            color: variables.$greyD;
            font-weight: 500;

            span {
                color: variables.$dark;
            }
        }
    }

    .content {
        padding: 10px;
        border-bottom: 1px solid variables.$grey200;
    }

    .address {
        display: flex;
        width: 100%;
        & > span {
            svg {
                color: variables.$primary;
            }
        }
        .details {
            margin-left: 10px;
            width: 100%;

            & > p {
                margin: 0;
                font-size: 12px;
                margin-bottom: 10px;
                text-transform: capitalize;
            }

            & > div {
                font-size: 10px;
                @include mixin.flexBetween;
                align-items: center;

                span {
                    @include mixin.itemsCenter;
                    // max-width: 30%;
                    margin-left: 5px;
                }

                .span {
                    color: variables.$greyD;
                }
            }
        }
    }

    .additional_info {
        background-color:  variables.$secondary100;
        padding: 10px;
        border-bottom: 1px solid variables.$grey200;
        h3,
        p {
            margin: 0;
            font-size: 12px;
            word-break: break-all;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 5px;
        }
        p {
            font-weight: 500;
            color: variables.$greyD;
        }
    }
    .create_at {
        @include mixin.itemsCenter;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 5px;
        p {
            margin: 0;
        }
    }
    .status {
        @include mixin.itemsCenter;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        // color: variables.$greyD;
        p {
            margin: 0;
            background-color: variables.$primary;
            color: variables.$white;
            padding: 3px 5px;
            border-radius: 4px;
            text-transform: capitalize;
        }

        .orange {
            background-color: variables.$orange;
        }
        .blue {
            background-color: rgba($color: variables.$blue, $alpha: 0.8);
        }
        .error {
            background-color: variables.$error;
        }
        .sky500 {
            background-color: #87ceeb;
        }
        .sky700 {
            background-color: #4682b4;
        }
        .grayD {
            background-color: variables.$greyD;
        }
        .warning200 {
            background-color: variables.$warning200;
        }
    }
    .payable {
        h3 {
            @include mixin.flexBetween;
            font-size: 12px;
            margin: 10px 0;

            b {
                color: variables.$primary;
                font-weight: 500;
            }
        }
        p {
            margin: 0;
            font-size: 10px;
            color: variables.$greyD;

            span {
                font-size: 12px;
                color: variables.$primary;
            }
        }
        .order_details_btn {
            @include mixin.itemsCenter;
            justify-content: space-between;

            button {
                @include mixin.buttonOutline;
                @include mixin.borderRadius;
                background-color: rgba($color: variables.$primary, $alpha: 0.1);
                padding: 10px;
                font-size: 14px;
                font-weight: 600;
                white-space: nowrap;
                margin: 0;
                border: none;
            }
            // a {
            //     background-color:variables.$grey200;
            //     // border: 1px solid variables.$grey300;
            //     color: variables.$dark;
            //     padding: 10px;
            //     font-size: 14px;
            //     font-weight: 600;
            //     display: block;
            //     width: 100%;
            //     text-align: center;
            //     @include mixin.borderRadius;
            // }
        }
    }
    .online_pay {
        // padding-top: 10px;
        width: 100%;
        p {
            color: orange;
        }
        button {
            @include mixin.buttonOutline;
            @include mixin.borderRadius;
            background-color: variables.$primary !important;
            color: variables.$white !important;
            padding: 10px;
            margin: 0;
        }
    }
}

.cart_prescription_view {
    padding: 10px;

    h3 {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 10px;
    }
    .items_wrp {
        max-width: 110px;
        min-width: 110px;
        margin-right: 10px;
        @include mixin.lastChildMargin;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid variables.$grey300;
        .remove {
            cursor: pointer;
            position: absolute;
            top: auto;
            background-color: rgba($color: variables.$dark, $alpha: 0.8);
            color: variables.$white;
            bottom: 0;
            font-size: 12px;
            font-weight: 400;
            padding: 8px;
            width: 100%;
        }

        .img_wrp {
            img {
                aspect-ratio: 1;
                object-fit: contain;
            }
        }
    }
}

.not_found {
    padding: 10px;
    height: 60vh;
    @include mixin.itemsCenter;
    justify-content: center;
    flex-direction: column;

    svg {
        width: 200px;
        height: 200px;
    }
    img {
        max-width: 244px;
    }

    .content {
        @include mixin.itemsCenter;
        justify-content: center;
        flex-direction: column;
        p {
            background-color: variables.$secondary200;
            padding: 10px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            @include mixin.borderRadius;

            &::before,
            &::after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 1.5px;
                //
                vertical-align: middle;
                background-color: variables.$dark;
            }
            &::before {
                margin-right: 10px;
            }
            &::after {
                margin-left: 10px;
            }
        }
        span {
            font-weight: 400;
            font-size: 14px;
            color: variables.$greyD;
        }
    }
}

$registerBg: #004bb8;

.track_order_wrp {
    @include mixin.borderRadius(6px, 6px, 0, 0);
    @include mixin.boxShadow;
    padding: 10px;
    border: 1px solid variables.$grey200;
    // max-width: 375px;
    margin: 0 auto;
    background-color: variables.$white;

    .track_order_item {
        font-size: 12px;
        color: variables.$greyD;
        display: flex;

        .link_status {
            display: block;
            text-decoration: underline;
            color: variables.$primary;
            padding: 3px 0;
            font-weight: 500;
        }

        p {
            margin: 0;
        }
        .time {
            min-width: 65px;
            max-width: 65px;
        }

        .line {
            min-width: 35px;
            max-width: 35px;
            @include mixin.flexCenter;
            justify-content: center;
            flex-direction: column;
            min-height: 60px;

            svg {
                color: variables.$greyD;
                margin-bottom: 5px;
                width: 28px;
                height: 28px;
            }
            span {
                display: block;
                width: 2px;
                height: 100%;
                background-color: variables.$greyD;
                margin-bottom: 5px;
            }
        }

        .status {
            // max-width: 160px;
            padding-left: 10px;
            h3 {
                margin: 0;
                font-size: 14px;
                color: variables.$dark;
                margin-bottom: 5px;
            }
            p {
                margin-bottom: 20px;
            }
        }

        &.lastLineRemove {
            align-items: flex-start;

            .line {
                min-height: 0;

                svg {
                    width: 18px;
                    height: 18px;
                }
                span {
                    display: none;
                }
            }
        }
    }
    .process {
        .line {
            svg {
                animation: zoomInOut 0.6s ease-in-out infinite alternate; // 2s duration, infinite loop, alternate direction
                transform-origin: center;
                ellipse {
                    color: variables.$primary;
                }
            }
            @keyframes zoomInOut {
                0% {
                    transform: scale(0.7);
                }
                100% {
                    transform: scale(1.1);
                }
            }
        }
    }
    .active_order {
        .line {
            svg {
                color: variables.$primary;
                width: 26px;
                height: 26px;
            }

            span {
                background-color: variables.$primary;
            }
        }
    }
    .error {
        .line {
            svg {
                color: variables.$error;
            }
            span {
                background-color: variables.$error;
            }
        }
    }
}
.order_again_wrp {
    padding: 0 10px;
    .order_again {
        @include mixin.buttonOutline;
        background-color: rgba($color: variables.$primary, $alpha: 0.1);
        @include mixin.borderRadius;
        padding: 12px;
        font-weight: 500;
        font-size: 14px;
        color: variables.$primary;
    }
}

.bottom_qry {
    text-align: center;
    border: 1px solid variables.$grey200;
    @include mixin.borderRadius(0, 0);
    border-top: none;
    background-color: variables.$white;

    span {
        color: variables.$greyD;
        font-size: 12px;
        font-weight: 400;
    }
    h3 {
        font-size: 14px;
        color: variables.$greyD;
        margin-top: 5px;
        font-weight: 500;

        .call {
            color: variables.$primary;
        }
        b {
            color: variables.$dark;
        }

        a {
            color: $registerBg;
        }
    }
}

.order_cancel_btn {
    background-color: rgba($color: variables.$error, $alpha: 0.1);
    color: variables.$error;
    border: none;
    @include mixin.borderRadius;
    font-size: 12px;
    font-weight: 500;
    padding: 5px;
}
.order_cancel_btn.cancel {
    background-color: variables.$grey300;
    color: variables.$white;
}

.shopping_cart_offer_card {
    // margin: 10px 0;
    border: 1px dashed variables.$primary;
    @include mixin.boxShadow;
    @include mixin.borderRadius;
    background: rgba($color: variables.$primary, $alpha: 0.1);
    padding: 10px;

    .item {
        @include mixin.itemsCenter;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    .wrapper {
        @include mixin.flexColumn;
        align-items: flex-start;
    }
    & > div {
        @include mixin.itemsCenter;
        font-size: 12px;
        font-weight: 600;
        color: variables.$primary;

        svg {
            @include mixin.itemsCenter;
            margin-right: 10px;
            width: 24px;
            height: 24px;
        }
    }
}
.wrap_rider_review {
    margin: 10px variables.$cp;
    @include mixin.boxShadow2;
    @include mixin.border;
    background-color: variables.$white;
    @include mixin.borderRadius;
    padding: 10px;
}
.wrap_card_product {
    margin: 10px variables.$cp;
    @include mixin.boxShadow2;
    @include mixin.border;
    background-color: variables.$white;
    @include mixin.borderRadius;

    .card_product_wrap {
        border-bottom: 1px solid variables.$grey200;
        padding: 10px;
        &:last-child {
            border: none;
        }
        .card_product {
            // @include mixin.itemsCenter;
            display: flex;
            // width: 100%;
            color: variables.$dark;
           
            .img_wrap {
                min-width: 60px;
                min-height: 60px;
                max-width: 60px;
                max-height: 60px;
                overflow: hidden;
                // border-radius: 100%;
                @include mixin.borderRadius;
                border: 1px solid variables.$grey300;
                // @include mixin.itemsCenter;
                position: relative;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    aspect-ratio: 1;
                }
    
                .top_off {
                    @include mixin.absolute(0, 0, 0, 10px);
                    width: 22px;
                    height: 17px;
    
                    span {
                        position: relative;
                        border-radius: 0;
                        font-size: 7px;
                        font-weight: 900;
                        @include mixin.itemsCenter;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        text-align: center;
                        // padding: 8px 5px 5px 5px;
                        padding-top: 3px;
                        color: variables.$white;
                        background-color: variables.$blue;
                        z-index: 1;
    
                        &::before {
                            content: "";
                            position: absolute;
                            z-index: -1;
                            top: 17px;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            background-image: url("../../../services/utils/svg/Vector\ 4.svg");
                            background-size: 100%;
                            background-repeat: no-repeat;
                            transform: rotate(0deg);
                        }
                    }
                }

                .flash_off {
                    @include mixin.absolute(0, 0, 0, 50%);
                    transform: translateX(-50%);
                    height: 12px;
                    width: 50px;
                    @include mixin.itemsCenter;
    
                    span {
                        border-radius: 0 0 6px 6px;
                        font-size: 7px;
                        font-weight: 500;
                        width: 50px;
                        height: 12px;
                        position: relative;
                        background-color: transparent;
                        z-index: 1;
                        background-image: url("../../../services/utils//svg/flash_sale_bg_1.svg");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        @include mixin.itemsCenter;
                        justify-content: center;
                        color: variables.$white;
    
                        svg {
                            width: 3.084px;
                            height: 6px;
                            margin-right: 3px;
                            height: auto;
                            @include mixin.itemsCenter;
                        }
                    }
                }
            }
    
            .content {
                padding-left: 10px;
                width: 100%;
            }
    
            .packed {
                font-size: 12px;
                font-weight: 500;
                color: variables.$white;
                @include mixin.itemsCenter;
                background-color: rgba($color: variables.$primary, $alpha: 1);
                padding: 2px 6px;
                margin-bottom: 5px;
                margin-left: 5px;
                border-radius: 4px;
            }
    
            .waiting {
                font-size: 12px;
                font-weight: 500;
                color: variables.$white;
                @include mixin.itemsCenter;
                padding: 2px 6px;
                border-radius: 4px;
                margin-bottom: 5px;
                margin-left: 5px;
                background-color: rgba($color: variables.$orange, $alpha: 1);
            }
    
            .replace {
                font-size: 12px;
                font-weight: 500;
                color: variables.$white;
                @include mixin.itemsCenter;
                padding: 2px 6px;
                border-radius: 4px;
                background-color: rgba($color: variables.$error, $alpha: 1);
                margin-left: 5px;
                margin-bottom: 5px;
    
                &:first-child {
                    margin-left: 0;
                }
            }
            .delete {
                font-size: 12px;
                font-weight: 500;
                color: variables.$white;
                @include mixin.itemsCenter;
                padding: 2px 6px;
                border-radius: 4px;
                background-color: rgba($color: variables.$error, $alpha: 0.1);
                margin-left: 5px;
                margin-bottom: 5px;
    
                svg {
                    width: 16px;
                    height: 16px;
                    color: variables.$error;
                }
            }
    
            .details_wrap {
                @include mixin.flexBetween;
                height: 100%;
                // align-items: center;
                margin-bottom: 3px;
    
                .details {
                    @include mixin.flexColumn;
                    justify-content: space-evenly;
                    // justify-content: space-between;
                    max-width: 60%;
                    color: variables.$dark;
    
                    h3 {
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0;
                    }
                    span {
                        font-size: 10px;
                        color: variables.$greyD;
                    }
                    p {
                        font-size: 10px;
                        margin: 0;
                        @include mixin.itemsCenter;
                        svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 5px;
                        }
                    }
                }
    
                .actions {
                    // max-width: 50%;
    
                    .icon {
                        @include mixin.itemsCenter;
                        justify-content: flex-end;
    
                        span {
                            @include mixin.itemsCenter;
                            margin-right: 5px;
                            @include mixin.lastChildMargin;
    
                            svg {
                                color: variables.$grey300;
                                width: 20px;
                                height: 20px;
                            }
                        }
    
                        .delivery_icon {
                            // svg {
                            //     width: 12px !important;
                            //     height: 12px !important;
                            // }
                            p {
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
    
                    .price {
                        display: flex;
                        // justify-content: flex-end;
                        justify-content: space-between;
                        flex-direction: column;
                        align-items: flex-end;
                        // height: 100%;
                        del {
                            font-size: 10px;
                            color: variables.$greyD;
                        }
                        b {
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                    .quantity {
                        display: flex;
                        //flex-wrap: wrap;
                        justify-content: flex-end;
                        margin-top: 5px;
                        // margin-bottom: 3px;
    
                        & > span {
                            font-size: 12px;
                            color: variables.$greyD;
                        }
                    }
    
                    .qrt_info {
                        font-size: 12px;
                        color: variables.$greyD;
                    }
    
                    @media only screen and (max-width: 320px) {
                        .price {
                            @include mixin.flexColumn;
                            justify-content: flex-end;
                            margin-top: 5px;
    
                            del {
                                @include mixin.flexConEnd;
                            }
                            b {
                                @include mixin.flexConEnd;
                            }
                        }
                    }
    
                    .cart_tags {
                        @include mixin.flexBetween;
    
                        button {
                            margin-left: 5px;
                        }
                    }
                    @media only screen and (max-width: 320px) {
                        .cart_tags {
                            width: 100%;
                            @include mixin.flexColumn;
                            align-items: flex-end;
                            button {
                                margin-top: 5px;
                            }
                        }
                    }
                }
    
                .label {
                    margin-top: 2px;
                    color: variables.$greyD;
                }
            }
    
            .bottom {
                // & > p {
                //     color: variables.$error;
                //     font-size: 10px;
                //     margin: 0;
                // }
    
                // .regular {
                //     svg {
                //         color: variables.$primary;
                //     }
                // }
    
                // .details {
                //     display: flex;
                // }
    
                @include mixin.flexColumn;
    
                .button_list {
                    @include mixin.itemsCenter;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
    
                .button_review {
                    @include mixin.itemsCenter;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    font-size: 12px;
                    font-weight: 500;
                    color:  rgba($color: variables.$primary, $alpha: 1);
                    text-decoration: underline;
                    cursor: pointer;
                }
    
                .replace_note {
                    font-size: 12px;
                    margin: 0;
                    color: variables.$error;
                    text-align: end;
                }
            }
        }
    }
   
    .not_found {
        padding: 10px;
        height: 50vh;
        @include mixin.itemsCenter;
        justify-content: center;
    }
}

.addition_info {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: variables.$grey500;
    word-break: break-all;

    h3 {
        color: variables.$greyD;
        margin: 0;
    }
}

.invoice_details {
    @include mixin.border;
    @include mixin.borderRadius;
    @include mixin.boxShadow;
    margin-bottom: 10px;

    .wrap {
        padding: 10px;
    }

    margin-top: 10px;
    .invoice_top {
        & > div {
            @include mixin.itemsCenter;
            justify-content: space-between;

            p {
                margin: 0;
                font-size: 12px;
                font-weight: 500;
                padding: 10px 0;
                text-transform: capitalize;
            }
            span {
                color: variables.$error;
                font-size: 12px;
            }
            .dark {
                color: variables.$dark;
            }
        }
        .success {
            color: variables.$primary;
        }

        .dropdown {
            p {
                // padding: 0;
                @include mixin.itemsCenter;

                svg {
                    @include mixin.itemsCenter;
                    width: 8px;
                    height: 8px;
                    rotate: -90deg;
                    margin-left: 5px;
                }
            }
            .primary {
                color: variables.$primary;
            }

            &.open {
                p {
                    padding-bottom: 10px;
                    svg {
                        rotate: 0deg;
                    }
                }
            }
        }

        .dropdown_item {
            padding: 0 0 0 10px;
            flex-direction: column;
            & > div {
                @include mixin.itemsCenter;
                justify-content: space-between;
                width: 100%;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                p {
                    padding: 0;
                    color: variables.$greyD;
                }
                span {
                    color: variables.$greyD;
                }
            }
        }
    }
    .invoice_bottom {
        border-top: 1px dashed variables.$grey300;
        padding-top: 10px;
        h3 {
            @include mixin.itemsCenter;
            justify-content: space-between;
            margin: 0;
            font-size: 14px;
            font-weight: 500;

            span {
                &:last-child {
                    color: variables.$primary;
                }
            }

            .paid {
                color: variables.$primary;
                // font-weight: bold;
            }
        }
        & > div {
            @include mixin.flexColumn;

            button {
                @include mixin.buttonOutline;
                background-color: rgba($color: variables.$primary, $alpha: 0.1);
                font-size: 14px;
                font-weight: 600;
                @include mixin.borderRadius;
                padding: 10px;
                margin: 0;
                margin-top: 10px;
            }

            p {
                margin: 0;
                font-size: 10px;
                font-weight: 400;

                a {
                    color: variables.$primary;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }

        .pay_cash {
            @include mixin.itemsCenter;
            color: variables.$greyD;
            margin: 10px 0 0 0;
            font-size: 12px;
            font-weight: 500;
            margin-right: 5px;

            a {
                color: variables.$primary;
                font-size: 12px;
                font-weight: 600;
                margin-right: 5px;
            }
        }
    }
    .delivery_type {
        @include mixin.itemsCenter;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 400;
        margin-top: 10px;

        p {
            margin-top: 0;
        }

        .primary {
            color: variables.$primary;
        }
        .error {
            color: variables.$error;
        }
    }
}

.pay_cash_timeline {
    @include mixin.itemsCenter;
    color: variables.$greyD;
    margin: 10px 0 0 0;
    a {
        color: variables.$primary;
        font-size: 12px;
        font-weight: 600;
        margin-right: 5px;
        text-decoration: underline;
    }
}

.select_reason {
    margin-top: 10px;
    label {
        @include mixin.itemsCenter;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
        text-transform: capitalize;
    }
    span {
        @include mixin.itemsCenter;

        svg {
            @include mixin.itemsCenter;
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }
    .active_item {
        color: variables.$primary;
        svg {
            color: variables.$primary;
            background-color: transparent;
        }
    }

    button {
        @include mixin.itemsCenter;
        justify-content: center;
        width: 100%;
        padding: 12px;
        background-color: variables.$primary;
        color: variables.$white;
        border: none;
        @include mixin.borderRadius;
        margin-top: 20px;
    }
}

.cancel_by_User {
    border: 1px solid variables.$error;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    // background-color: rgba($color: variables.$error, $alpha: 0.1);
    @include mixin.borderRadius;
    @include mixin.itemsCenter;
    justify-content: center;
    margin-bottom: 10px;
    color: variables.$greyD;
}

.modal_design {
    background-color: transparent !important;
}

.select_button {
    border: none;
    background-color: variables.$primary;
    color: variables.$white;
    padding: 10px 15px;
    @include mixin.borderRadius;
    font-weight: 500;
    font-size: 12px;
}

.confirm_replace {
    background-color: variables.$white;
    @include mixin.borderRadius;

    .icon {
        width: 60px;
        height: 60px;
        background-color: rgba($color: variables.$warning, $alpha: 0.1);
        @include mixin.itemsCenter;
        justify-content: center;
        border-radius: 100%;
        margin: 0 auto;

        svg {
            @include mixin.itemsCenter;
            width: 30px;
            height: 30px;
            color: variables.$warning;
        }
    }

    h1 {
        margin-top: 10px;
        font-size: 18px;
        font-weight: bolder;
        text-align: center;
        margin-bottom: 0;
    }
    & > p {
        font-size: 12px;
        font-weight: 400;
        text-align: center;

        b {
            color: variables.$error;
            font-weight: 700;
        }
        span {
            color: variables.$primary;
            font-weight: 700;
        }
    }
    span {
        font-size: 12px;
        color: variables.$greyD;
        @include mixin.itemsCenter;
        justify-content: center;
        text-align: center;
        margin: 0 10%;
    }
    .table_custom {
        & > div {
            @include mixin.itemsCenter;
            justify-content: space-between;

            &:first-child {
                p {
                    color: variables.$error;
                }
            }

            &:last-child {
                p {
                    margin-top: 5px;
                }
            }
            p {
                margin: 0;
                margin-bottom: 10px;
                font-size: 12px;
                color: variables.$primary;
            }
        }
    }

    .buttons_list {
        @include mixin.itemsCenter;
        justify-content: space-between;
        margin-top: 10px;
        button {
            padding: 10px;
            background-color: variables.$primary;
            border: none;
            color: variables.$white;
            @include mixin.borderRadius;
            width: 100%;
            border: 1px solid variables.$primary;

            &:first-child {
                background-color: transparent;
                color: variables.$greyD;
                border: 1px solid variables.$grey200;
            }
        }
    }
}

.modal_text_pac_waiting {
    font-size: 12px;
    font-weight: 500;
    color: variables.$greyD;
}

.pay_err {
    color: variables.$orange !important;
    margin: 10px 0 !important;
    font-size: 12px !important;
}
