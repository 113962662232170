@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.addReviewBlock {
    position: relative;
    .productCard {
        display: flex;
        background-color: variables.$un_gray;
        padding: 4px;
        border-radius: 10px;
        margin-bottom: 16px;
        .img_wrap {
            min-width: 70px;
            min-height: 70px;
            max-width: 70px;
            max-height: 70px;
            overflow: hidden;
            // border-radius: 100%;
            @include mixin.borderRadius;
            border: 1px solid variables.$grey300;
            // @include mixin.itemsCenter;
            background-color:variables.$grey200;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                aspect-ratio: 1;
            }
        }
    }
    .productInfo {
        padding-left: 16px;
        .label {
            margin-top: 2px;
            color: variables.$ltGreyScale700;
            font-size: 10px;
            font-weight: 400;
           
        }
        .productTitle {
            font-size: 12px;
            font-weight: 500;
            color: variables.$ltTitle;
            margin-top: 5px;
            line-height: 19.2px;
        }
        .subTitle {
            font-size: 12px;
            font-weight: 400;
            color: variables.$greyD;
           
        }
    }
    .formGroup {
        padding-bottom: 16px;
        position: relative;
        .label {
           color: variables.$ltTitle;
           font-size: 14px;
           font-weight: 500;
           margin-bottom: 12px;
           display: flex;
           align-items: center;
           gap: 5px;
           .optional {
            color:  variables.$ltGreyScale600;
           }
           .anonymous {
            margin-right: 16px;
           }
        }
        .optional {
            color:  variables.$ltGreyScale600;
            font-size: 12px;
            margin: 0;
        }
        .input {
            margin-top: 12px;
            display: flex;
            align-items: center;
            text-wrap: nowrap;
            flex-wrap: wrap;
            gap: 12px;
        }
        .textarea {
            font-family: 'Inter', sans-serif;
            &.error {
                border: 1px solid variables.$error200;
                &:focus {
                    border: 1px solid variables.$error200;
                }
            }
            &::placeholder {
              color: variables.$grey300;
              font-family: 'Inter', sans-serif;
            }
            width: 100%;
            border: 1px solid rgba(variables.$greyD, 0.25);
            border-radius: 8px;
          
            padding: 12px;
            transition:
              border 0.1s ease-in,
              outline 0.1s ease-out;
          
            &:focus {
              border: 1px solid variables.$primary;
              outline: none;
              background-color: transparent;
            }
        }
    }
    .chip {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35px;
        font-size: 12px;
        font-weight: 500;
        background-color: variables.$un_gray;
        border: 1px solid variables.$ltGreyScale300;
        padding: 8px 12px;
        border-radius: 6px;
        cursor: pointer;
        margin: 0;
        &.active {
            color: variables.$ltPrimary;
            background-color: #F1F7F7;
            border: 1px solid variables.$ltPrimary;
        }
    }
    .inputReview {
        margin-top: 16px;
    }
    .important {
        color: variables.$ltTitle;
        padding: 12px;
        background: variables.$un_gray;
        width: auto;
        font-size: 12px;
        font-weight: 500;
        border-radius: 8px;
        margin-bottom: 16px;
        .importantTitle {
            padding: 0;
            margin: 0;
        }
        .importantList li {
            color: variables.$ltGreyScale700;
            font-weight: 400;
            margin-bottom: 5px;
        }
    }
    .info {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: #F5F8FF;
        border-radius: 8px;
        padding: 6px 12px;
        margin-bottom: 24px;
        .icon {
            color: #0062FF;
        }
        .infoText {
            font-size: 12px;
            font-weight: 500;
            line-height: 24px;
            // margin-bottom: 4px !important;
        }
    }
    .reviewText {
        position: absolute;
        bottom: 24px;
        right: 10px;
        font-size: 12px;
        color:  variables.$ltGreyScale600;
    }
    .errorMessage {
        color: variables.$error200;
        margin: 6px 0;
        font-size: 12px;
        font-weight: 400;
    }
    .action {
        position: sticky;
        bottom: -16px;      
        width: 100%;    
        display: flex;    
        justify-content: center;
        background-color: variables.$white; 
        padding: 16px 0 20px;       
        border-top: 1px solid variables.$ltGreyScale300;

        .submitBtn {
            width: 100%;           
            max-width: 100%;        
            background-color: variables.$primary;
            color: variables.$white;
            border-radius: 6px;
            padding: 8px 20px;
            height: 40px;
            border: none;
            font-size: 14px;
            font-weight: 500;
            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
   
}
