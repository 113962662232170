@use '@/services/scss/variables.scss';
@use '@/services/scss/mixin.scss';

.StarsPercent {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, #F7C948 var(--percent), #CBD5E0 var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}